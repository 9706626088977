<template>
    <div class="top_header">
        <div class="header_nav">
            <div class="gis_logo" @click="goIndex"><img src="../assets/images/gis_logo.png" alt=""></div>
            <div class="nav_box" v-if="!pageType">
                <router-link target="_blank" class="nav_item" v-for="(item,index) in navList" :key="index" :class="{'active':navIndex==index}" :to="routePath+item.router">{{item.name}}</router-link>
            </div>
            <div class="page_title" v-else>{{pageTitle}}</div>
        </div>
        <div class="header_search">
            <div class="search_box">
                <input type="text" placeholder="搜索">
                <i class="el-icon-search"></i>
            </div>
            <div class="header_date">
                <div class="date">{{dateTime}}</div>
                <div class="city_info">{{areaAndWeather}}</div>
            </div>
        </div>
    </div>
</template>
<script>
import AMap from 'AMap' // 引入高德地图
export default {
    data(){
        return{
            navList:[
                {
                    name:'总览',
                    router:''
                },
                {
                    name:'重点项目',
                    router:'?type=projects'
                },
                {
                    name:'指挥调度',
                    router:'?type=command'
                },
                {
                    name:'数字农业',
                    router:'?type=agriculture'
                },
                {
                    name:'文化旅游',
                    router:'?type=travel'
                },
                {
                    name:'生态振兴',
                    router:'?type=ecology'
                }
            ],
            navIndex:0,
            dateTime:'4月2日 12:12:20',
            areaAndWeather:'淄博市 多云 12℃',
            pageType:'',
            routePath:'',
        }
    },
    computed:{
        pageTitle(){
            if(this.pageType=='projects'){
                return '重点项目GIS一张图'
            }else if(this.pageType=='command'){
                return '指挥调度GIS一张图'
            }else if(this.pageType=='agriculture'){
                return '数字农业GIS一张图'
            }else if(this.pageType=='travel'){
                return '文化旅游GIS一张图'
            }else if(this.pageType=='ecology'){
                return '生态振兴GIS一张图'
            }
        }
    },
    methods:{
        goIndex(){
            this.$router.push('/')
        }
    },
    created(){
        setInterval(()=>{
            this.dateTime = this.$moment().format("MMMDo hh:mm:ss")
        },1000)
    },
    mounted(){
        console.log('000',this.$route.path)
        this.routePath = this.$route.path
        //实例化城市查询类
        var citysearch = new AMap.CitySearch();
        //创建天气查询实例
        var weather = new AMap.Weather();
        //自动获取用户IP，返回当前城市
        citysearch.getLocalCity((status, result)=>{
            if (status === 'complete' && result.info === 'OK') {
                if (result && result.city && result.bounds) {
                    var cityinfo = result.city;
                    // var citybounds = result.bounds;
                    this.areaAndWeather = cityinfo
                    //执行实时天气信息查询
                    weather.getLive(result.city, (err, data)=>{
                        this.areaAndWeather = `${this.areaAndWeather} ${data.weather} ${data.temperature}℃`
                    });
                }
            } else {
                console.log(result.info)
            }
        });
    },
    created(){
        this.pageType = this.$route.query.type
    }
}
</script>